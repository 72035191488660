import React from "react"
import MobileFooter from "./MobileFooter"


const Footer = () => {


    return (
        <div className="footer-container">
             <MobileFooter />
        </div>
    );

    }

export default Footer;